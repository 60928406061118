import React from "react";
import styled from "@emotion/styled";

import Container from "../container";
import {md} from "../../utils/breakpoints";

const Seperator = styled.div<any>`
    margin: 160px auto 100px;
    width: 140px;
    height: 1px;
    background: ${props => props.theme.borderColor};

    @media (max-width: ${md}px) {
        margin: 50px auto;
    }
`;

const Title = styled.div<any>`
    font-size: 36px;
    font-family: ${props => props.theme.headerFontFamily};
`;

const Description = styled.div<any>`
    margin-top: 20px;
    font-size: 18px;
`;

const ValuesList = styled.ul<any>`
    list-style: none;
    margin: 40px -10px 100px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
`;

const ValuesListItem = styled.li<any>`
    display: block;
    flex: 1 1 calc(25% - 60px);
    background: #fff;
    padding: 10px;
    min-height: 200px;
    min-width: 200px;
    margin: 10px;
`;

const ValuesListItemContent = styled.span<any>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border: 1px solid ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 24px;
    height: 100%;
    text-align: center;
`;

export interface ICoreValuesProps {
    coreValues: Array<string>;
    description: string;
    title: string;
}

export default function CoreValues(props: ICoreValuesProps): JSX.Element {
    const {
        coreValues,
        description,
        title
    } = props;

    return (
        <Container>
            <Seperator />
            <Title>
                {title}
            </Title>
            <Description>
                {description}
            </Description>
            <ValuesList>
                {coreValues.map(v => (
                    <ValuesListItem key={v}>
                        <ValuesListItemContent>
                            {v}
                        </ValuesListItemContent>
                    </ValuesListItem>
                ))}
            </ValuesList>
        </Container>
    );
}