import React from "react";
import styled from "@emotion/styled";
import CountUp from "react-countup";

import Container from "../container";
import {md} from "../../utils/breakpoints";

const Card = styled.div`
    background: #fff;
    padding: 10px;
    margin-top: 60px;
`;

const CardContent = styled.div<any>`
    border: 1px solid ${props => props.theme.primaryColor};
    padding: 40px;

    @media (max-width: ${md}px) {
        padding: 20px;
    }
`;

const CardTitle = styled.div<any>`
    font-size: 36px;
    font-family: ${props => props.theme.headerFontFamily};
    text-align: center;
    margin-top: 20px;
`;

const CardDescription = styled.div<any>`
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
    padding-bottom: 60px;
    border-bottom: 1px solid ${props => props.theme.primaryColor};
`;

const MeasureList = styled.ul`
    padding: 0;
    margin: 40px -10px 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
`;

const MeasureListItem =  styled.li`
    display: block;
    flex: 1 1 calc(25% - 80px);
    text-align: center;
    min-width: 200px;
    margin: 20px 10px;
`;

const Measure = styled.div<any>`
    font-size: 48px;
    font-family: ${props => props.theme.headerFontFamily};
    color: ${props => props.theme.secondaryColor};
`;

const MeasureLabel = styled.div`
    font-size: 18px;
    margin-top: 10px;
`;

export interface IImpactCardProps {
    impactCardDescription: string;
    impactCardLabel1: string;
    impactCardLabel2: string;
    impactCardLabel3: string;
    impactCardLabel4: string;
    impactCardMeasure1: string;
    impactCardMeasure2: string;
    impactCardMeasure3: string;
    impactCardMeasure4: string;
    impactCardTitle: string;
}

function convertImpactMeasure(str: string): CountUp {
    const match      = str.match(/[0-9]/gi);
    const firstIndex = str.indexOf(match[0]);
    const lastIndex  = str.lastIndexOf(match[match.length - 1]);

    const num = parseInt(str.slice(firstIndex, lastIndex + 1), 10);
    const prefix = str.slice(0, firstIndex);
    const suffix = str.slice(lastIndex +1);

    return (
        <CountUp start={0} end={num} duration={2} prefix={prefix} suffix={suffix} />
    );
}

export default function IImpactCard(props: IImpactCardProps): JSX.Element {
    const {
        impactCardDescription,
        impactCardLabel1,
        impactCardLabel2,
        impactCardLabel3,
        impactCardLabel4,
        impactCardMeasure1,
        impactCardMeasure2,
        impactCardMeasure3,
        impactCardMeasure4,
        impactCardTitle
    } = props;

    return (
        <Container>
            <Card>
                <CardContent>
                    <CardTitle>{impactCardTitle}</CardTitle>
                    <CardDescription>{impactCardDescription}</CardDescription>
                    <MeasureList>
                        <MeasureListItem>
                            <Measure>
                                {convertImpactMeasure(impactCardMeasure1)}
                            </Measure>
                            <MeasureLabel>{impactCardLabel1}</MeasureLabel>
                        </MeasureListItem>
                        <MeasureListItem>
                            <Measure>{convertImpactMeasure(impactCardMeasure2)}</Measure>
                            <MeasureLabel>{impactCardLabel2}</MeasureLabel>
                        </MeasureListItem>
                        <MeasureListItem>
                            <Measure>{convertImpactMeasure(impactCardMeasure3)}</Measure>
                            <MeasureLabel>{impactCardLabel3}</MeasureLabel>
                        </MeasureListItem>
                        {/* <MeasureListItem>
                            <Measure>{convertImpactMeasure(impactCardMeasure4)}</Measure>
                            <MeasureLabel>{impactCardLabel4}</MeasureLabel>
                        </MeasureListItem> */}
                    </MeasureList>
                </CardContent>
            </Card>
        </Container>
    );
}