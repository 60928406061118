import React from "react";
import {graphql} from "gatsby";

import Layout from "../components/layout/layout";
import PageHeader from "../components/pageHeader";
import ImpactCard from "../components/team/impactCard";
import FounderNote from "../components/team/founderNote";
import WhyUs from "../components/team/whyUs";
import CoreValues from "../components/team/coreValues";

export default function Team({data}): JSX.Element {
    const {
        impactCardDescription,
        impactCardLabel1,
        impactCardLabel2,
        impactCardLabel3,
        impactCardLabel4,
        impactCardMeasure1,
        impactCardMeasure2,
        impactCardMeasure3,
        impactCardMeasure4,
        impactCardTitle,
        whyUsTitle,
        whyUsContent,
        founderNoteTitle,
        founderTitleHighlight,
        founderNoteName,
        founderNoteContent,
        headerTitle,
        headerDescription,
        coreValues,
        coreValuesDescription,
        coreValuesTitle,
        founderImage
    } = data.contentfulTeamPage;

    const {teamLinkTitle} = data.contentfulLayout;

    return (
        <Layout title={headerTitle}>
            <PageHeader
                noSeperator
                title={headerTitle}
                description={headerDescription.headerDescription}
                titleSubtext={teamLinkTitle} />
            <ImpactCard 
                impactCardDescription={impactCardDescription}
                impactCardLabel1={impactCardLabel1}
                impactCardLabel2={impactCardLabel2}
                impactCardLabel3={impactCardLabel3}
                impactCardLabel4={impactCardLabel4}
                impactCardMeasure1={impactCardMeasure1}
                impactCardMeasure2={impactCardMeasure2}
                impactCardMeasure3={impactCardMeasure3}
                impactCardMeasure4={impactCardMeasure4}
                impactCardTitle={impactCardTitle}/>
            <FounderNote
                quoteMarkURL={data.quoteFile.publicURL}
                founderImageURL={founderImage.file.url}
                founderNoteTitle={founderNoteTitle}
                founderTitleHighlight={founderTitleHighlight}
                founderNoteName={founderNoteName}
                founderNoteContent={founderNoteContent.founderNoteContent}/>
            <WhyUs
                whyUsContent={whyUsContent.whyUsContent}
                whyUsTitle={whyUsTitle} />
            <CoreValues coreValues={coreValues} description={coreValuesDescription.coreValuesDescription} title={coreValuesTitle}/>
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulTeamPage {
            impactCardDescription
            impactCardLabel1
            impactCardLabel2
            impactCardLabel3
            impactCardLabel4
            impactCardMeasure1
            impactCardMeasure2
            impactCardMeasure3
            impactCardMeasure4
            impactCardTitle
            whyUsTitle
            whyUsContent {
                whyUsContent
            }
            founderNoteTitle
            founderTitleHighlight
            founderNoteName
            founderNoteContent {
                founderNoteContent
            }
            headerTitle
            headerDescription {
                headerDescription
            }
            coreValues
            coreValuesDescription {
                coreValuesDescription
            }
            coreValuesTitle
            founderImage {
                file {
                    url
                }
            }
        }
        contentfulLayout {
            teamLinkTitle
        }
        quoteFile: file(relativePath: {eq: "quote-mark.svg"}) {
            publicURL
        }
    }
`;