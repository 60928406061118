import React from "react";
import styled from "@emotion/styled";

import {md} from "../../utils/breakpoints";
import Container from "../container";
import Underliner from "../underliner";

const Seperator = styled.div<any>`
    margin: 100px auto;
    width: 140px;
    height: 1px;
    background: ${props => props.theme.borderColor};

    @media (max-width: ${md}px) {
        margin: 50px auto;
    }
`;

const NoteContainer = styled.div<any>`
    position: relative;
    margin-bottom: 80px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        background: ${props => props.theme.primaryColor};
        z-index: -1;
    }
`;

const NoteContent = styled.div<any>`
    display: flex;
    flex-wrap: wrap;
    padding: 80px 0;
    background: ${props => props.theme.primaryColor};
    color: #fff;
    align-items: center;
`;

const NoteTitle = styled.div<any>`
    font-size: 36px;
    font-family: ${props => props.theme.headerFontFamily};
    margin: 0 0 60px;
`;

const NoteText = styled.div<any>`
    font-size: 20px;
    line-height: 1.4;
    position: relative;
    white-space: pre-wrap;

    &:before {
        content: '';
        position: absolute;
        font-family: ${props => props.theme.headerFontFamily};
        width: 24px;
        height: 24px;
        left: -40px;
        top: -10px;
        background-image: url(${props => props.quoteMarkUrl});
        background-size: 24px 24px;
    }
`;

const NoteAuthor = styled.div<any>`
    font-size: 20px;
    margin-top: 40px;
    font-style: italic;
`;

const FounderImageWrapper = styled.div<any>`
    position: absolute;
    width: 100%;

    > img {
        position: absolute;
        right: -20px;
        top: 0;
        margin-top: -280px;
        max-width: 375px;
        margin-bottom: 0;
    }
`;


const NoteLeft = styled.div<any>`
    flex: 2 0 60%;
    padding: 0 40px;
`;

const NoteRight = styled.div<any>`
    flex: 1 0 40%;
    position: relative;
    max-width: 400px;

    @media (max-width: 900px) {
        display: none;
    }
`;

export interface IFounderNoteProps {
    founderNoteTitle: string;
    founderTitleHighlight: string;
    founderNoteName: string;
    founderNoteContent: string;
    founderImageURL: string;
    quoteMarkURL: string;
}

export default function FounderNote(props: IFounderNoteProps): JSX.Element {
    const {
        founderNoteContent,
        founderNoteTitle,
        founderTitleHighlight,
        founderNoteName,
        founderImageURL,
        quoteMarkURL
    } = props;

    return (
        <div>
            <Seperator />
            <NoteContainer>
                <Container>
                    <NoteContent>
                        <NoteLeft>
                            <NoteTitle><Underliner text={founderNoteTitle} underline={founderTitleHighlight} /></NoteTitle>
                            <NoteText quoteMarkUrl={quoteMarkURL}>{founderNoteContent}</NoteText>
                            <NoteAuthor>{founderNoteName}</NoteAuthor>
                        </NoteLeft>
                        <NoteRight>
                            <FounderImageWrapper>
                                <img src={founderImageURL} alt="Founder Headshot" />
                            </FounderImageWrapper>
                        </NoteRight>
                    </NoteContent>
                </Container>
            </NoteContainer>
        </div>
    );
}