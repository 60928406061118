import React from "react";
import styled from "@emotion/styled";

import Container from "../container";

const Title = styled.div<any>`
    font-size: 36px;
    font-family: ${props => props.theme.headerFontFamily};
    margin-top: 160px;
`;

const Content = styled.div<any>`
    margin-top: 40px;
    font-size: 24px;
    line-height: 1.4;
    white-space: pre-wrap;
`;

export interface IWhyUsProps {
    whyUsTitle: string;
    whyUsContent: string;
}

export default function WhyUs(props: IWhyUsProps): JSX.Element {
    const {
        whyUsContent,
        whyUsTitle
    } = props;

    return (
        <Container>
            <Title>
                {whyUsTitle}
            </Title>
            <Content>
                {whyUsContent}
            </Content>
        </Container>
    );
}